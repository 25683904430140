// universalServiceWorker.js
// NOTICE:
// 1. For every global variable, put inside of `scope`, so that we can ensure same behavior for true/fallback service worker.
// 2. For functions potentially need `self` or `globalThis`, they are mostly can't be "universal", try not to implement them in this file, put them to `serviceWorker/serviceWorker.js` instead.

import { processPusherTargets } from './universalPusher.js';

export const scope = {
  counter: [],
  criticalCounter: [],
  jointCounter: [],
  pusher: {},
};
export const setTestCountArrow = count => {
  return (scope.count = count);
};
export const setTestCount = function (count) {
  return (scope.count = count);
};
export const getTestObjectCountArrow = () => {
  return scope.count;
};
export const getTestObjectCount = function () {
  return scope.count;
};
export const getThisValueArrow = function (key) {
  return this[key];
};
export const getThisValue = function (key) {
  return this[key];
};

export const syncPusherTarget = async function () {
  clearTimeout(scope.pusher?.timeoutId);
  const callback = syncPusherTarget.bind(this);
  scope.pusher.timeoutId = setTimeout(
    callback,
    +scope.remoteConfig?.data?.PUSHER_CHANNEL_SYNC_TIMEOUT_MSEC || 2000
  );

  const pusherInstances = (this.Pusher || window.Pusher || globalThis.Pusher)
    .instances;
  // we choose first 'connected' one or the newest one
  const pusherInstance =
    pusherInstances?.find?.(
      instance => 'connected' === instance.connection.state
    ) || pusherInstances?.[pusherInstances.length - 1];
  if (!pusherInstance)
    throw new Error('missing pusherInstance', {
      pusherInstances,
      pusherInstance,
    });

  const tabIds = [];
  if (self?.clients) {
    const clients = await self.clients.matchAll({
      type: 'window',
      includeUncontrolled: false, // it's default true on Safari?
    });
    tabIds.push(...clients.map(client => client.id));
  } else {
    // we need 'null' tabId to access contents from fallback workerMessenger
    tabIds.push(null);
  }

  if (tabIds.length) processPusherTargets({ tabIds, scope, pusherInstance });

  return { pusherInstances, pusherInstance, tabIds };
};
